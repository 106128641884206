import React from "react";
import styled from "styled-components";

import { DATA } from "../data";
import { SectionTitle } from "./SectionTitle";
import { MOBILE_BREAKPOINT } from "../style";

const ServicesWrapper = styled.div`
  width: 100%;
  padding: 128px 0;
  background: #101010;
`;

const ServicesContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin: auto;

  @media (min-width: ${MOBILE_BREAKPOINT}) {
    flex-wrap: wrap;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
  }
`;

const Service = styled.div`
  @media (min-width: ${MOBILE_BREAKPOINT}) {
    padding: 96px;
    width: 50%;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 32px;
    width: 100%;
  }
`;

const ServiceTitle = styled.h4`
  color: white;
  margin-bottom: 4px;
  font-size: 20px;
`;

const ServiceSubtitle = styled.h6`
  color: white;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 200;
  opacity: 0.85;
`;

const ServiceDescription = styled.p`
  color: white;
  font-size: 13px;
  opacity: 0.7;
`;

export class Services extends React.Component {
  render() {
    return (
      <ServicesWrapper id="Services">
        <SectionTitle light>Services</SectionTitle>
        <ServicesContent>
          {DATA.services.map((s, k) => (
            <Service key={k}>
              <ServiceTitle>{s.title}</ServiceTitle>
              <ServiceSubtitle>{s.subtitle}</ServiceSubtitle>
              <ServiceDescription>{s.description}</ServiceDescription>
            </Service>
          ))}
        </ServicesContent>
      </ServicesWrapper>
    );
  }
}
