import React from "react";
import styled from "styled-components";

import { withPrefix } from "gatsby";

import { MOBILE_BREAKPOINT } from "../style";

const PersonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity ease 0.5s;
  cursor: pointer;
  padding: 32px;

  ${props => props.css};

  &:hover {
    opacity: 0.5;
  }

  @media (min-width: ${MOBILE_BREAKPOINT}) {
    width: 50%;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 100%;
  }
`;

const PersonImageWrapper = styled.div`
  width: 192px;
  height: 192px;
  border-radius: 50%;
  overflow: hidden;
`;

const PersonImage = styled.img`
  width: 100%;
  height: 100%;
`;

const PersonName = styled.h5`
  font-size: 18px;
  margin-top: 16px;
  color: ${props => (props.light ? "white" : "black")};
`;

const PersonPosition = styled.h6`
  font-size: 14px;
  opacity: 0.7;
  margin-top: 4px;
  color: ${props => (props.light ? "white" : "black")};
`;

export class Person extends React.Component {
  render() {
    const { selectPerson, children, person, light, css } = this.props;
    return (
      <PersonWrapper
        onClick={() => {
          if (selectPerson) {
            selectPerson();
          }
        }}
        css={css}
      >
        <PersonImageWrapper>
          <PersonImage src={withPrefix(`/${person.image}`)} />
        </PersonImageWrapper>

        <PersonName light={light}>{person.name}</PersonName>
        <PersonPosition light={light}>{person.position}</PersonPosition>
        {children}
      </PersonWrapper>
    );
  }
}
