import React from "react";
import styled from "styled-components";

import { Globalstyle } from "../style/reset";
import { DATA } from "../data";
import { PersonDetail } from "../components/PersonDetail";
import { Leadership } from "../components/Leadership";
import { Header } from "../components/Header";
import { Introduction } from "../components/Introduction";
import { Mission } from "../components/Mission";
import { Services } from "../components/Services";
import { Contact } from "../components/Contact";
import { Footer } from "../components/Footer";

const LandingPageWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

class LandingPage extends React.Component {
  state = {
    selectedPerson: undefined
  };

  render() {
    const selectedPerson =
      this.state.selectedPerson !== undefined
        ? DATA.leadership[this.state.selectedPerson]
        : undefined;

    return (
      <LandingPageWrapper>
        <Globalstyle />
        <PersonDetail
          selectedPerson={selectedPerson}
          close={() => this.setState({ selectedPerson: undefined })}
        />
        <Header />
        <Introduction />

        <Mission />

        <Services />
        <Leadership
          selectPerson={index => this.setState({ selectedPerson: index })}
        />
        <Contact />
        <Footer />
      </LandingPageWrapper>
    );
  }
}

export default LandingPage;
