import React from "react";

import styled from "styled-components";

import { DATA } from "../data";
import { SectionTitle } from "./SectionTitle";
import { MOBILE_BREAKPOINT } from "../style";

const MissionWrapper = styled.div`
  width: 100%;
  position: relative;
  background: #101010;

  @media (min-width: ${MOBILE_BREAKPOINT}) {
    min-height: 800px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    min-height: 600px;
  }
`;

const MissionContent = styled.div`
  @media (min-width: ${MOBILE_BREAKPOINT}) {
    padding: 256px 32px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 128px 32px;
  }
`;

const MissionStatement = styled.p`
  color: white;
  text-align: center;
  max-width: 1000px;
  margin: auto;

  @media (min-width: ${MOBILE_BREAKPOINT}) {
    line-height: 32px;
    font-size: 18px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    line-height: 26px;
    font-size: 14px;
  }
`;

export class Mission extends React.Component {
  render() {
    return (
      <MissionWrapper id="Mission">
        <MissionContent>
          <SectionTitle light>Mission</SectionTitle>
          <MissionStatement>{DATA.mission}</MissionStatement>
        </MissionContent>
      </MissionWrapper>
    );
  }
}
