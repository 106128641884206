import React from "react";
import styled from "styled-components";

import { withPrefix } from "gatsby";

import { DATA } from "../data";

const GetInTouchButton = styled.div`
  border: 0;
  background-color: ${DATA.color};
  color: white;
  padding: 12px 32px;
  height: 48px;
  text-transform: uppercase;
  font-size: 14px;
  opacity: 0.8;
  transition: all ease 0.5s;
  margin-top: 64px;
  outline: none;
  font-family: "Carrois Gothic", sans-serif;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const Subtitle = styled.h3`
  color: white;
  font-weight: bold;
  font-size: 16px;
  margin-top: 16px;
  opacity: 0.7;
`;

const TextLogo = styled.img`
  width: 256px;
  margin-top: 32px;
  filter: invert(100%);
`;

const Logo = styled.img`
  width: 96px;
`;

const ContactWrapper = styled.div`
  padding: 128px 0;
  height: 600px;
  width: 100%;
  position: relative;
  background-color: #101010;
`;

const Cover = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#101010, rgba(0, 0, 0, 0), #101010);
`;

const ImageBackground = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.1;
  z-index: 0;
`;

const ContactContent = styled.div`
  padding: 256px 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export class Contact extends React.Component {
  render() {
    return (
      <ContactWrapper>
        <ImageBackground src={withPrefix(`/${DATA.backgroundBuilding}`)} />
        <Cover />
        <ContactContent>
          <Logo src={withPrefix(`/${DATA.logo}`)} />
          <TextLogo src={withPrefix(`/${DATA.textLogo}`)} />
          <Subtitle>Financial Technology Expertise</Subtitle>
          <GetInTouchButton
            onClick={() => {
              window.location.href = `mailto:${DATA.contact.email}`;
            }}
          >
            Get in touch
          </GetInTouchButton>
        </ContactContent>
      </ContactWrapper>
    );
  }
}
