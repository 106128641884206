import React from "react";
import styled from "styled-components";

import { withPrefix } from "gatsby";

import { DATA } from "../data";
import { MOBILE_BREAKPOINT } from "../style";

const IntroductionContainer = styled.div`
  background-color: #101010;
  height: 100vh;
  width: 100%;
  position: relative;
  min-height: 650px;
  overflow: hidden;
`;

const IntroOuterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  @media (min-width: ${MOBILE_BREAKPOINT}) {
    background: linear-gradient(#101010, rgba(0, 0, 0, 0.3), #101010);
    flex-direction: row;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    background: linear-gradient(#101010, rgba(0, 0, 0, 0.7), #101010);
    padding: 32px;
  }
`;

const IntroInnerWrapper = styled.div``;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: row;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
  }
`;

const MainLogoWrapper = styled.div``;

const MainLogo = styled.img`
  width: 144px;
`;

const TextWrapper = styled.div`
  @media (min-width: ${MOBILE_BREAKPOINT}) {
    margin-left: 64px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-top: 64px;
  }
`;

const Line = styled.h1`
  color: white;
  font-size: 48px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    text-align: center;
    font-size: 28px;
  }
`;

const SubtitleWrapper = styled.div`
  display: flex;
  margin-left: -64px;
  margin-top: 48px;

  @media (min-width: ${MOBILE_BREAKPOINT}) {
    justify-content: center;
    flex-direction: row;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
    align-items: center;
  }
`;

const Skill = styled.h3`
  color: white;
  font-size: 24px;
  font-weight: 100;
  opacity: 0.8;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin: 4px 0;
  }
`;

const Separator = styled(Skill)`
  margin: 0 16px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: none;
  }
`;

const VideoWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 1;
  pointer-events: none;
`;

export class Introduction extends React.Component {
  state = {
    video: {
      show: false,
      width: 0,
      height: 0
    }
  };

  componentDidMount() {
    const { width, height } = this.computeVideoDimensions();
    this.setState({ video: { show: true, width, height } });
    // window.addEventListener("resize", () => {
    //   console.log('resizing', window)
    //   this.setState({
    //     width: window.innerWidth,
    //     height: window.innerHeight
    //   });
    // });
  }

  computeVideoDimensions = () => {
    // 1920 x 1080px

    let width = window.innerWidth;
    let height = width * (1080 / 1920);
    if (height > window.innerHeight) {
      return { width, height };
    }

    height = window.innerHeight;
    width = height * (1920 / 1080);
    return { width, height };
  };

  render() {
    return (
      <IntroductionContainer>
        <VideoWrapper>
          {this.state.video.show && (
            <video
              width={this.state.video.width}
              height={this.state.video.height}
              autoPlay
              muted
              loop
            >
              <source src={withPrefix(`/${DATA.video}`)} type="video/mp4" />
            </video>
          )}
        </VideoWrapper>
        <IntroOuterWrapper
          style={
            {
              // width: this.state.video.width || undefined,
              // height: this.state.video.height || undefined
            }
          }
        >
          <IntroInnerWrapper>
            <TitleWrapper>
              <MainLogoWrapper>
                <MainLogo src={withPrefix(`/${DATA.logo}`)} />
              </MainLogoWrapper>
              <TextWrapper>
                <Line>Next Generation</Line>
                <Line>Financial Technology</Line>
              </TextWrapper>
            </TitleWrapper>
            <SubtitleWrapper>
              {DATA.introTags.map((s, k) => (
                <React.Fragment key={k}>
                  <Skill>{s}</Skill>
                  {k < DATA.introTags.length - 1 && <Separator>|</Separator>}
                </React.Fragment>
              ))}
            </SubtitleWrapper>
          </IntroInnerWrapper>
        </IntroOuterWrapper>
      </IntroductionContainer>
    );
  }
}
