import React from "react";
import styled from "styled-components";

import { DATA } from "../data";
import { Person } from "./Person";
import { SectionTitle } from "./SectionTitle";
import { MOBILE_BREAKPOINT } from "../style";

const CompanyLeadership = styled.div`
  background: white;
  padding: 128px 0;
`;

const PeopleWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 800px;
  margin: auto;
  justify-content: center;

  @media (min-width: ${MOBILE_BREAKPOINT}) {
    flex-wrap: wrap;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
    align-items: center;
  }
`;

export class Leadership extends React.Component {
  render() {
    const { selectPerson } = this.props;
    return (
      <CompanyLeadership id="Leadership">
        <SectionTitle dark>Leadership</SectionTitle>
        <PeopleWrapper>
          {DATA.leadership.map((p, k) => (
            <Person selectPerson={() => selectPerson(k)} person={p} key={k} />
          ))}
        </PeopleWrapper>
      </CompanyLeadership>
    );
  }
}
