import styled from "styled-components";

export const SectionTitle = styled.h2`
  color: ${props => (props.light ? "white" : "black")};
  font-size: 22px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 64px;
`;
