import React from "react";
import styled from "styled-components";

import { Person } from "./Person";
import { MOBILE_BREAKPOINT } from "../style";

const PersonDetailWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.95);
  opacity: ${props => (props.show ? 1 : 0)};
  z-index: ${props => (props.show ? 100 : -1)};
  transition: opacity ease 0.5s;
  display: flex;
  padding: 32px;
  width: 100%;

  @media (min-width: ${MOBILE_BREAKPOINT}) {
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    overflow-y: auto;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  max-width: 600px;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(255, 255, 255, 0.1),
    rgba(0, 0, 0, 0)
  );
  margin: 32px 0;
`;

const SelectedPersonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  min-height: 800px;
`;

const PersonDescription = styled.p`
  color: white;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.8;
  margin-bottom: 32px;
`;

const CancelIcon = styled.div`
  position: absolute;
  right: 24px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  transition: all ease 0.5s;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: white;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

export class PersonDetail extends React.Component {
  render() {
    const { selectedPerson, close } = this.props;
    return (
      <PersonDetailWrapper show={selectedPerson !== undefined} onClick={close}>
        <CancelIcon onClick={close} />
        {selectedPerson && (
          <SelectedPersonContent
            onClick={e => {
              e.stopPropagation();
            }}
            style={{ pointerEvents: "none" }}
          >
            <Person person={selectedPerson} light css={{ paddingBottom: 0 }} />
            <Divider />
            <PersonDescription>{selectedPerson.description}</PersonDescription>
          </SelectedPersonContent>
        )}
      </PersonDetailWrapper>
    );
  }
}
